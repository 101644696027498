export default function EmailIcon() {
    return (
        <svg
            width="219"
            height="226"
            viewBox="0 0 219 226"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M213.234 152.629C209.365 162.757 185.893 161.45 179.444 169.447C172.755 177.735 178.842 200.348 170.09 206.022C161.251 211.766 143.023 196.994 132.861 199.728C122.784 202.445 114.461 224.405 103.817 223.854C93.5508 223.321 87.5151 200.726 77.4039 196.857C67.2755 192.988 47.7066 205.764 39.7105 199.316C31.4221 192.627 39.8825 170.702 34.2078 161.949C28.43 153.128 5.00919 151.958 2.25785 141.778C-0.459101 131.702 19.1786 118.942 19.7289 108.315C20.2619 98.0492 1.93113 83.3467 5.8002 73.2355C9.66928 63.1072 33.1417 64.414 39.5901 56.4179C46.2793 48.1295 40.192 25.5169 48.9447 19.8423C57.7834 14.0989 76.011 28.8701 86.1738 26.136C96.2506 23.419 104.573 1.4599 115.218 2.01017C125.484 2.54324 131.519 25.1386 141.631 29.0077C151.759 32.8768 171.328 20.1002 179.324 26.5487C187.612 33.2379 179.152 55.1627 184.827 63.9154C190.57 72.7541 213.991 73.9234 216.742 84.1033C219.459 94.1801 199.821 106.939 199.271 117.567C198.772 127.815 217.103 142.518 213.234 152.629Z"
                fill="#B6E8E7"
            />
            <path
                d="M213.234 152.629C209.365 162.757 185.893 161.45 179.444 169.447C172.755 177.735 178.842 200.348 170.09 206.022C161.251 211.766 143.023 196.994 132.861 199.728C122.784 202.445 114.461 224.405 103.817 223.854C93.5508 223.321 87.5151 200.726 77.4039 196.857C67.2755 192.988 47.7066 205.764 39.7105 199.316C31.4221 192.627 39.8824 170.702 34.2078 161.949C28.43 153.128 5.00919 151.958 2.25785 141.778C-0.459102 131.702 19.1786 118.942 19.7289 108.315C20.2619 98.0492 1.93113 83.3467 5.8002 73.2355C9.66928 63.1072 33.1417 64.414 39.5901 56.418C46.2793 48.1295 40.192 25.5169 48.9447 19.8423C57.7834 14.0989 76.011 28.8701 86.1738 26.136C96.2506 23.419 104.573 1.4599 115.218 2.01017C125.484 2.54324 131.519 25.1386 141.631 29.0077C151.759 32.8768 171.328 20.1002 179.324 26.5487C187.612 33.2379 179.152 55.1627 184.827 63.9154C190.57 72.754 213.991 73.9234 216.742 84.1033C219.459 94.1801 199.821 106.939 199.271 117.567C198.772 127.815 217.103 142.518 213.234 152.629Z"
                stroke="black"
                strokeWidth="5"
                strokeMiterlimit="10"
            />
        </svg>
    )
}
